import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import ToolTip from './ToolTip';
import IconInfo from './Icons/IconInfo';

const InfoToolTip = ({
  text,
  content,
  disabled = false,
  onClick,
  color,
  size = '16px',
  lineHeight = '16px',
  children,
  ...rest
}) => {
  return (
    <ToolTip
      disabled={disabled}
      theme="backdrop"
      {...rest}
      content={
        content ? (
          content
        ) : (
          <Text
            size="15px"
            style={{
              lineHeight: '21px',
              letterSpacing: 'normal',
              textTransform: 'none',
            }}
            textAlign="center"
          >
            {text}
          </Text>
        )
      }
    >
      <Box
        as="span"
        flex={children ? true : false}
        direction={children ? 'row' : 'column'}
        style={{
          ...(rest.style || {}),
          display: children ? 'flex' : 'inline-block',
          verticalAlign: 'middle',
          lineHeight: children ? 'inherit' : lineHeight,
          cursor: 'pointer',
        }}
        focusIndicator={false}
        onClick={onClick}
        justify="center"
        gap="0.3rem"
      >
        <IconInfo size={size} color={color} />
        {children && <Box margin={{ top: '-0.3rem' }}>{children}</Box>}
      </Box>
    </ToolTip>
  );
};

InfoToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  lineHeight: PropTypes.string,
  children: PropTypes.node,
};

export default memo(InfoToolTip);
