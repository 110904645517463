import React, { useContext, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Text, Box, ResponsiveContext, Layer } from 'grommet';
import { Close } from 'grommet-icons';
import InfoToolTip from '../InfoToolTip';

import formatCurrency from '../../lib/formatCurrency';

const DiscountedPriceLabel = ({
  fullPrice,
  color = 'dark-6',
  size = '1.06667rem',
  explanation,
  isDiscounted = true,
  prompt,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const viewportSize = useContext(ResponsiveContext);
  const isMobile = viewportSize === 'small';

  return (
    <Box direction="row" gap="xsmall" align="center" {...rest}>
      <Text color={color} size={size} style={{ textTransform: 'initial' }}>
        {prompt && !isDiscounted ? (
          <>
            {prompt}{' '}
            {explanation && (
              <InfoToolTip
                text={explanation}
                disabled={isMobile}
                onClick={isMobile ? () => setOpen(!open) : undefined}
              />
            )}
          </>
        ) : (
          isDiscounted && (
            <>
              Was{' '}
              <Text
                color={color}
                style={{ textDecoration: 'line-through' }}
                size={size}
              >
                {formatCurrency(fullPrice)}
              </Text>
            </>
          )
        )}
      </Text>
      {explanation && isDiscounted && (
        <InfoToolTip
          text={explanation}
          disabled={isMobile}
          onClick={isMobile ? () => setOpen(!open) : undefined}
        />
      )}
      {open && (
        <Layer
          onEsc={() => setOpen(false)}
          onClickOutside={() => setOpen(false)}
          responsive={false}
        >
          <Box
            border={{ side: 'all', color: 'black', size: 'small' }}
            background="white"
            width={{ min: isMobile ? '80vw' : 0 }}
          >
            <Box
              flex={{ shrink: 1, grow: 0 }}
              pad="small"
              align="end"
              onClick={() => setOpen(false)}
            >
              <Close color="black" size="18px" />
            </Box>
            <Box
              pad={{ horizontal: 'medium', bottom: 'medium' }}
              height={{ min: '40vh' }}
              justify="center"
              align="center"
            >
              <Text textAlign="center">{explanation}</Text>
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  );
};

DiscountedPriceLabel.propTypes = {
  fullPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  size: PropTypes.string,
  explanation: PropTypes.string,
  isDiscounted: PropTypes.bool,
  prompt: PropTypes.string,
};

export default memo(DiscountedPriceLabel);
