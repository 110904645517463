import React from 'react';
import { Blank } from 'grommet-icons';

const IconInfo = (props) => (
  <Blank
    viewBox="0 0 161 161"
    {...props}
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="M76.822.062c-14.27.656-27.98 5.041-39.998 12.796-7.917 5.108-15.035 11.747-20.74 19.352-4.256 5.664-7.734 11.856-10.366 18.433C1.935 60.109 0 70.205 0 80.484c0 15.577 4.425 30.599 12.87 43.68 5.11 7.916 11.747 15.035 19.353 20.739 5.664 4.256 11.855 7.734 18.44 10.367 9.46 3.782 19.555 5.717 29.84 5.717 15.57 0 30.593-4.425 43.673-12.87 7.917-5.109 15.035-11.747 20.74-19.353 4.256-5.663 7.734-11.855 10.366-18.439 3.783-9.46 5.718-19.556 5.718-29.841 0-15.57-4.425-30.592-12.87-43.672-5.11-7.917-11.747-15.036-19.353-20.74-5.664-4.256-11.855-7.735-18.432-10.367-9.582-3.83-19.752-5.751-30.126-5.704-1.198.007-2.72.034-3.397.06zm8.655 7.206c6.604.48 12.457 1.645 18.615 3.708 4.629 1.557 9.643 3.878 13.994 6.476 19.373 11.578 32.378 31.404 35.2 53.653.433 3.404.568 5.657.568 9.379 0 3.728-.135 5.982-.568 9.379-2.253 17.749-11.003 34.11-24.577 45.952-6.61 5.759-14.149 10.272-22.384 13.378-6.015 2.274-12.6 3.783-19.041 4.365-2.612.236-3.722.284-6.78.284-3.73 0-5.982-.136-9.38-.569-9.94-1.258-19.589-4.594-28.21-9.75-19.373-11.578-32.378-31.405-35.2-53.66-.433-3.397-.568-5.65-.568-9.379 0-3.722.135-5.975.568-9.379C9.967 53.363 18.717 37.001 32.291 25.16c8.33-7.26 18.304-12.613 28.846-15.475 5.46-1.475 10.793-2.287 16.842-2.551 1.259-.054 6.063.034 7.498.135z"
      fillRule="nonzero"
    />
    <path
      d="M73.466 40.344v14.562h14.427V40.344H73.466zM73.466 64.359v60.393h14.427V64.359H73.466z"
      fillRule="nonzero"
    />
  </Blank>
);

export default IconInfo;
